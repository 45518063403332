import i18n from 'i18n-js';
import { NativeBaseProvider } from 'native-base';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { ThemeProvider as StyledComponentThemeProvider } from 'styled-components/native';

import colors from '../../constants/Colors';
import theme from '../../constants/NativeBaseTheme';
import LanguageEN from '../../languages/en.json';
import LanguageFA from '../../languages/fa.json';
import { fetchMessagesList } from '../../redux/slices/MessagesSlice';
import { fetchUserProfile } from '../../redux/slices/UserSlice';
import ActionSheetProvider from './ActionSheetProvider';
import ModalProvider from './ModalProvider';

i18n.translations = {
  en: LanguageEN,
  fa: LanguageFA,
};
i18n.locale = 'fa';

const ThemeContext = React.createContext({
  isDark: false,
  colors,
  setScheme: () => {},
});

export const ThemeProvider = ({ children, colorScheme, navigation }) => {
  const dispatch = useDispatch();
  const [isDark, setIsDark] = React.useState(colorScheme === 'dark');
  const userReduxState = useSelector((state) => state.user);

  const defaultTheme = {
    isDark,
    colors,
    setScheme: (scheme) => setIsDark(scheme === 'dark'),
  };

  useEffect(() => {
    setIsDark(colorScheme === 'dark');
  }, [colorScheme]);

  useEffect(() => {
    i18n.locale = userReduxState?.userSettings?.language || 'fa';
  }, [userReduxState?.userSettings?.language]);

  useEffect(() => {
    switch (userReduxState?.userSettings?.darkmode || 'dark') {
      case 'dark':
        setIsDark(true);
        break;
      case 'light':
        setIsDark(false);
        break;
      case 'inherit':
        setIsDark(colorScheme === 'dark');
        break;

      default:
        setIsDark(false);
        break;
    }
  }, [userReduxState?.userSettings?.darkmode]);

  useEffect(() => {
    dispatch(fetchMessagesList());
    dispatch(fetchUserProfile());
  }, []);

  return (
    <ThemeContext.Provider value={defaultTheme}>
      <StyledComponentThemeProvider theme={{ myColors: colors, isDark }}>
        <NativeBaseProvider theme={theme(colors, isDark)}>
          {children}
          {__DEV__ && (
            <DebugDark onPress={() => setIsDark((prev) => !prev)}>
              <DebugDarkText>{isDark ? 'L' : 'D'}</DebugDarkText>
            </DebugDark>
          )}
          <ActionSheetProvider />
          <ModalProvider />
        </NativeBaseProvider>
      </StyledComponentThemeProvider>
    </ThemeContext.Provider>
  );
};

export const useTheme = () => React.useContext(ThemeContext);

const DebugDark = styled.TouchableOpacity`
  position: absolute;
  top: 100px;
  left: 15px;
  right: auto;
  opacity: 0.1;
`;
const DebugDarkText = styled.Text`
  color: ${(p) => (p.theme?.isDark ? p.theme.myColors.white : p.theme.myColors.dusk)};
  font-size: 38px;
`;
