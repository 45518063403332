import * as React from 'react';
import Svg, { G, Rect, Path, Defs, ClipPath } from 'react-native-svg';

const SvgComponent = (props) => (
  <Svg width={80} height={80} viewBox="0 0 535 535" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <G clipPath="url(#a)">
      <Rect width={535} height={535} rx={70} fill="#20CAFF" />
      <Path
        d="m188.787 153.642.81-7s-18-11.82-25.63-3.23a72.131 72.131 0 0 0 24.82 10.23ZM161.786 147.252a26.396 26.396 0 0 0-1.12 9.52c1.06 29.81 23.23 38.92 23.23 38.92l4.69-40.32a67.006 67.006 0 0 1-26.8-8.12Z"
        fill="#890353"
      />
      <Path
        d="M323.757 139.102c-15.32-24.28-74.93-46.6-106.5-31.32-18.35 8.91-28.181 26.54-33.841 44.54a87.957 87.957 0 0 0 16.72 3 62.87 62.87 0 0 1-17.44-.75c-4.3 14.53-6 29-7.57 39.11-5.28 34.58-24.4 163.5 43.36 159.6 32.361-1.86 68 21.56 99.38-32.68 23.78-41.07 46.49-117.15 5.891-181.5Z"
        fill="#F5846C"
      />
      <Path
        opacity={0.32}
        d="m244.256 114.762-14.35-11.27a57.54 57.54 0 0 0-12.64 4.29c-18.36 8.91-28.19 26.54-33.85 44.54a88.035 88.035 0 0 0 16.72 3c-5.82.562-11.69.309-17.44-.75-4.3 14.53-6 29-7.57 39.11-3.77 24.66-12.92 99.75 9.63 139.59 16.92 29.9 44.36 24.81 46.21 25.22-37.4-71.5 13.29-243.73 13.29-243.73Z"
        fill="#FFB3A6"
      />
      <Path
        d="M320.767 306.812s-43.77 100.4 148.8 110.51c35.05 1.85 43.93 108.31 43.93 108.31l-479.78-20.38s-21.76-101.67 57.89-102.31c142.42-1.14 104.35-80.12 104.35-80.12l124.81-16.01Z"
        fill="#F5846C"
      />
      <Path
        opacity={0.32}
        d="M134.605 399.582c5.35 8.67 39.18 40.06 57.6 29.08 32.16-19.18 23.55-57.72 18.32-73.49l-11.62 1.5s-3.78 34.29-64.3 42.91Z"
        fill="#FFB3A6"
      />
      <Path
        d="M91.607 402.942c21-.17 38-2 51.85-5.06 99.94 83.69 183.76 30.16 220.17-2.78 23 11.18 56.77 19.65 105.94 22.24 35.05 1.85 43.93 108.31 43.93 108.31l-479.78-20.4s-21.76-101.67 57.89-102.31Z"
        fill="#fff"
      />
      <Path
        d="M91.606 402.942c20.92 1.24 38-2 51.85-5.06 99.94 83.69 183.76 30.16 220.17-2.78 20.92 10.16 50.73 18.09 92.93 21.4 4.21.33 21.36 2.94 25 6 25.18 21.61 32 113.72 32 113.72H24.246s-16.09-138.21 67.36-133.28Z"
        fill="#fff"
      />
      <Path
        d="M383.806 114.422c-10.64 25.74-35.14 39.46-35.14 39.46 18.83-12.2 27.54-26.89 31.56-39.81 3.5-11.3 1.49-19.9.27-23.49-5.8-17.14-19.87-22.26-29.16-22.26-16.38 0-26.47 5.37-36.49 12.64-9.06-11.64-24.5-22.64-42.6-27.48-7.07-1.9-42-10.51-52.51 2.85-3.58 4.57-4.61 10.73-3.87 17.29-19.92-6.82-34.17-7.63-46.82 13-12.41 20.3-14.86 41.28-8.32 54.44a66.605 66.605 0 0 0 12.38 7.36 81.746 81.746 0 0 0 27 6.87 67.298 67.298 0 0 1-30.62-4.43 23.8 23.8 0 0 0 5.69 2.51l.46 1c10.89 23.64 31.73 24.59 49 19.93-4.34 8.1-14.94 13-21.09 16.35 14.69-.23 26.53-7.21 40.7-16.6 3-1.95 17.88-12.61 25.26-16.86 15.37-8.85 37.51-16.26 53.72 5 5.61 7.37 6.22 18.56 1.28 28.78-16.73 34.58 16.21 45.67 13.24 43-2.97-2.67-5.84-36.29 50.31-78.36 16.85-12.48 8.97-31.05-4.25-41.19Z"
        fill="#890353"
      />
      <G opacity={0.61}>
        <Path opacity={0.61} d="M376.236 133.952s18.75-14 10.16 18.27Z" fill="#890353" />
        <Path
          opacity={0.61}
          d="M376.236 133.952c2.51-3.9 9-7.21 12.68-2.8 4.34 5.91 1.08 15.66-2.52 21.07-.53-5.25.33-10.67-.23-15.63-.24-1.53-.41-2.62-1.39-3.41-2.49-1.24-5.8.18-8.54.77Z"
          fill="#fff"
        />
      </G>
      <Path
        opacity={0.39}
        d="M187.376 80.972c-7.7 12.13-15.81 29.9-4.47 42a43.087 43.087 0 0 0 6.11 5c8.19 5.75 19.51 9.66 28.73 4.27l3-1.87.85 3c3 8.53 7.87 7.18 13.68 2a13.6 13.6 0 0 0 1.43 1.83c3.41 3.31 8.68 2.07 12.45-.06l1.92-1.15c-3.24 3.32-7.78 6.14-12.65 5a9.144 9.144 0 0 1-4.59-2.51l2.2.14c-5.67 6.42-13.21 6.93-17.77-1a16.816 16.816 0 0 1-1.3-2.73l3.82 1.18c-11.28 7.78-25 3.59-34.94-3.55a65.237 65.237 0 0 1-5.81-4.83 33.828 33.828 0 0 1-3-3.65c-9.66-14.18-.49-32.45 10.36-43.08l-.02.01Z"
        fill="#fff"
      />
      <Path
        d="M323.236 223.962s10.67 40.46-20.46 50.36c-31.13 9.9-38.6-31.78-71.81-34.61-33.21-2.83-62.38 17.67-62.38 17.67s-.89 22.7-17.53 49.24c-4.73 7.53-6.56 18.17-1.15 30.47 2.65 6 13.63 18.07 39.69 23 44.22 8.43 116.46 14.84 135.39-9.35 24.44-31.21 18.07-116.72 18.07-116.72l-19.82-10.06Z"
        fill="#890353"
      />
      <Path
        d="M244.257 276.522c-7.1 5.68-17.23 5.9-25.7 3.79a30.81 30.81 0 0 1-13.94-7.55 35.8 35.8 0 0 1 5.33-.14c11.69.46 22.64 2.13 34.31 3.9ZM325.906 210.912c4.94-13.19 19.07-25.63 28.24-22.36 10.33 3.69 15.42 27.68 4.71 41.19-7.24 9.13-22.59 14.59-30.59 8.23-7.75-6.15-4.87-20.37-2.36-27.06Z"
        fill="#F5846C"
      />
      <Path
        d="M335.217 204.212c.11-.37 10-12.48 15.49-7.33s4.07 15.57.26 17.49c0 0 5.24-11-.13-13.85-5.37-2.85-12.84 5.56-12.84 5.56s-3.93 1.9-2.78-1.87Z"
        fill="#890353"
      />
      <Path
        d="M293.406 440.402a8.85 8.85 0 0 1-3.522-.455 8.852 8.852 0 0 1-3.058-1.805c1.72-.44 3.41-.91 5.09-1.4.33.065.664.098 1 .1a6 6 0 0 0 4-1.65c1.807-.6 3.59-1.227 5.35-1.88a9.998 9.998 0 0 1-8.86 7.09ZM263.286 442.102l3.89 3.89a21.388 21.388 0 0 0 2.9-4.61c1.39-.19 2.77-.39 4.14-.61 0 .1-.1.2-.14.31l-.05.12a25.774 25.774 0 0 1-4.36 7.23l5.13 5.15c3.85-2.29 8.76-2.36 12.19 1.08a9.68 9.68 0 0 1-13.33 14c-3.8-3.34-3.83-8.51-1.45-12.51l-5.09-5.1a26.787 26.787 0 0 1-16 6.7v7.1c4.33 1.1 7.87 4.52 7.85 9.38a9.679 9.679 0 0 1-19.34.49c-.33-5.05 3.31-8.73 7.82-9.87v-7.15a25.368 25.368 0 0 1-6.4-1.32 29.226 29.226 0 0 1-9.46-5.47l-5.2 5.19c2.29 3.85 2.36 8.75-1.08 12.18a9.665 9.665 0 0 1-13.395.062 9.671 9.671 0 0 1-.605-13.382c3.34-3.81 8.51-3.83 12.51-1.45a764.97 764.97 0 0 1 5.18-5.17 26.546 26.546 0 0 1-5.19-8.86c1.42.3 2.84.58 4.25.83a22.471 22.471 0 0 0 3.49 5.48l4.32-4.31a136.145 136.145 0 0 0 27.42.62Zm12.52 23.75a6.074 6.074 0 0 0 4.298 1.786 6.074 6.074 0 0 0 4.302-1.776 6.084 6.084 0 0 0 1.326-6.626 6.066 6.066 0 0 0-1.316-1.974c-2.34-2.35-6.2-2-8.6 0-1.99 2.4-2.36 6.24-.01 8.59Zm-53-8.65c-2.41-2-6.25-2.36-8.61 0a6.087 6.087 0 0 0 4.297 10.39 6.084 6.084 0 0 0 4.303-1.78c2.33-2.36 1.96-6.21-.01-8.61h.02Zm17.43-14.81-6.07 6.06a23.528 23.528 0 0 0 6.05 3.66c2.318.962 4.77 1.56 7.27 1.77v-8.47a17.2 17.2 0 0 1-7.27-3.02h.02Zm3 31.89a6.093 6.093 0 0 0 6.085 5.843 6.087 6.087 0 0 0 6.085-5.843c0-3.32-3-5.79-6.08-6.09-3.15.32-6.12 2.77-6.13 6.13l.04-.04Zm7.94-20.36a23 23 0 0 0 13.44-5.34l-5.89-5.9a13.52 13.52 0 0 1-7.54 2.86c-.04 2.78-.05 5.59-.05 8.38h.04ZM205.737 440.322a9.998 9.998 0 0 1-9.68-9.55c1.31.55 2.61 1.08 3.91 1.59a6.499 6.499 0 0 0 5.77 4.35 5.58 5.58 0 0 0 3.32-1.12c1.32.42 2.63.83 3.94 1.21a9.085 9.085 0 0 1-3.217 2.612 9.086 9.086 0 0 1-4.043.908Z"
        fill="#FF5D3B"
      />
      <Path
        d="M144.627 398.012c.713.853 1.47 1.667 2.27 2.44a5.484 5.484 0 0 0-2.005 4.23 5.488 5.488 0 0 0 6.52 5.404 5.489 5.489 0 0 0 3.785-2.754c1.14.84 2.343 1.7 3.61 2.58a5.476 5.476 0 0 0 6.181 7.308 5.477 5.477 0 0 0 2.809-1.488c1.38.84 2.8 1.68 4.26 2.52a6.1 6.1 0 0 0-.06.74 5.49 5.49 0 0 0 9 4.19c1.49.78 3 1.55 4.55 2.32v.65a5.483 5.483 0 0 0 3.453 5.105 5.477 5.477 0 0 0 6.017-1.335c1.046.467 2.106.927 3.18 1.38a8.817 8.817 0 0 1-4.339 3.248 8.816 8.816 0 0 1-9.868-3.004 8.807 8.807 0 0 1-1.793-5.114 8.81 8.81 0 0 1-13.52-7.11 8.723 8.723 0 0 1-4.73 1.39 8.827 8.827 0 0 1-8.82-8.55 8.723 8.723 0 0 1-4.73 1.39 8.815 8.815 0 0 1-8.282-5.735 8.809 8.809 0 0 1 2.512-9.755v-.05ZM307.116 431.432a5.489 5.489 0 0 0 9.47-3.78 4.557 4.557 0 0 0 0-.64 182.708 182.708 0 0 0 4.56-2.33 5.485 5.485 0 0 0 5.833.768 5.48 5.48 0 0 0 3.167-4.958c0-.244-.02-.489-.06-.73a320.412 320.412 0 0 0 4.26-2.52 5.493 5.493 0 0 0 5.973 1.163 5.49 5.49 0 0 0 3.377-5.063c0-.657-.122-1.308-.36-1.92 1.27-.88 2.48-1.74 3.61-2.58a5.493 5.493 0 0 0 6.174 2.651 5.495 5.495 0 0 0 4.116-5.311 5.437 5.437 0 0 0-1.84-4.09l2.54-2.18a8.808 8.808 0 0 1 .743 11.804 8.811 8.811 0 0 1-11.673 1.906 8.83 8.83 0 0 1-8.81 8.54 8.718 8.718 0 0 1-4.74-1.39 8.832 8.832 0 0 1-8.81 8.55 8.69 8.69 0 0 1-4.73-1.39 8.823 8.823 0 0 1-6.244 8.198 8.816 8.816 0 0 1-9.756-3.318l3.2-1.38Z"
        fill="#FF5D3B"
      />
    </G>
    <Defs>
      <ClipPath id="a">
        <Rect width={535} height={535} rx={70} fill="#fff" />
      </ClipPath>
    </Defs>
  </Svg>
);

export default SvgComponent;
