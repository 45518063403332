import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import country from 'country-list-js';
import i18n from 'i18n-js';
import { Spinner } from 'native-base';
import React, { useState, useEffect } from 'react';
import { ScrollView } from 'react-native';
import { useSelector } from 'react-redux';
import styled from 'styled-components/native';

import HeaderWithIcons from '../../components/common/HeaderWithIcons';
import MYButton from '../../components/ui/MYButton';

function PaymentScreenContent({ navigation, selectedPlan }) {
  const userReduxState = useSelector((state) => state.user);
  const isRtl = userReduxState?.userSettings?.language?.toLowerCase() === 'fa';
  const [isPaymentElementLoaded, setIsPaymentElementLoaded] = useState(false);

  const userCurrency = country.findByIso2(userReduxState?.userInfo?.countryCode?.toUpperCase())?.currency?.code || 'USD';
  const findedCurrency = selectedPlan?.prices?.find((a) => a.currency === userCurrency);
  const price = findedCurrency
    ? findedCurrency?.price
    : selectedPlan?.prices?.length && selectedPlan?.prices?.find((a) => a.currency === 'USD')?.price;
  const oldprice = findedCurrency
    ? findedCurrency?.oldprice
    : selectedPlan?.prices?.length && selectedPlan?.prices?.find((a) => a.currency === 'USD')?.oldprice;
  const priceSign = findedCurrency ? country.findByIso2(userReduxState?.userInfo?.countryCode?.toUpperCase())?.currency?.symbol : '$';

  const stripe = useStripe();
  const elements = useElements();

  async function handlePayPress() {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    try {
      const { error } = await stripe.confirmPayment({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          return_url: 'https://farsidate.com',
        },
      });

      if (error) {
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Show error to your customer (for example, payment
        // details incomplete)
        alert(error.message || 'error');
        console.log(error);
      } else {
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
        goToSuccessPage('success', 'Your order is confirmed!');
      }
    } catch (error) {
      alert(error.message || 'error');
    }
  }

  return (
    <Container>
      <HeaderWithIcons title={i18n.t('payment.header_title')} onPress={() => navigation.goBack()} />

      <InnerContainer>
        <ScrollView>
          <AmountToPayContainer>
            <AmountToPayTitle>{i18n.t('payment.amount_to_pay:')}</AmountToPayTitle>

            <AmountToPayPrice>
              {price} {priceSign}
            </AmountToPayPrice>
          </AmountToPayContainer>
          {!isPaymentElementLoaded && <Spinner />}
          <PaymentElement onReady={() => setIsPaymentElementLoaded(true)} />
        </ScrollView>

        <TrustsIconsContainer>
          <TrustsIcons source={require('../../assets/images/secure_payment.png')} style={{ width: null, resizeMode: 'contain' }} />
        </TrustsIconsContainer>

        <Description isRtl={isRtl}>{i18n.t('subscription.description')}</Description>

        <ButtonsContainer>
          <MYButton color="red" style={{ width: '100%' }} onPress={handlePayPress}>
            {i18n.t('payment.btn_pay_for_the_order')}
          </MYButton>
        </ButtonsContainer>
      </InnerContainer>
    </Container>
  );
}

export default function PaymentScreen({ route, navigation }) {
  const paymentIntentClientSecret = route.params?.paymentIntent;
  const customerId = route.params?.customer;
  const selectedPlan = route.params?.selectedPlan;

  const startupReduxState = useSelector((state) => state.startup);

  const [stripePromise, setStripePromise] = useState(null);

  useEffect(() => {
    if (startupReduxState?.stripe?.stripe_publishableKey) {
      setStripePromise(loadStripe(startupReduxState?.stripe?.stripe_publishableKey));
    }
  }, [startupReduxState]);

  const options = {
    // passing the client secret obtained from the server
    clientSecret: paymentIntentClientSecret,
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <PaymentScreenContent navigation={navigation} selectedPlan={selectedPlan} />
    </Elements>
  );
}

const Container = styled.View`
  display: flex;
  background-color: ${(p) => (p.theme?.isDark ? p.theme.myColors.dark2 : p.theme.myColors.white)};
  flex: 1;
`;

const LoadingContainer = styled.View`
  display: flex;
  background-color: ${(p) => (p.theme?.isDark ? p.theme.myColors.dark2 : p.theme.myColors.white)};
  flex: 1;
  justify-content: center;
`;

const InnerContainer = styled.KeyboardAvoidingView`
  padding: 0 34px;
  flex: 1;
`;

const TrustsIconsContainer = styled.View`
  /* aspect-ratio: 1; */
  /* margin-top: -20px; */
  /* margin-bottom: -30px; */
`;

const TrustsIcons = styled.Image`
  height: 50px;
`;

const ButtonsContainer = styled.View`
  width: 100%;
  margin-bottom: 18px;
  flex-shrink: 0;
  margin-top: 34px;
`;

const Description = styled.Text`
  font-family: Gilroy-Medium;
  margin-top: 10px;
  font-size: 12px;
  color: ${(p) => (p.theme?.isDark ? p.theme.myColors.bluey_grey : p.theme.myColors.slate_blue)};
  padding: 10px 0;
  /* text-align: ${(p) => (p.isRtl ? 'right' : 'left')}; */
  text-align: center;
`;

const AmountToPayContainer = styled.View`
  margin-top: 34px;
  margin-bottom: 34px;
  padding: 20px 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid;
  border-color: ${(p) => (p.theme.isDark ? p.theme.myColors.dusk2 : p.theme.myColors.pale_grey)};
  border-radius: 18px;
  align-items: center;
`;

const AmountToPayTitle = styled.Text`
  color: ${(p) => (p.theme?.isDark ? p.theme.myColors.bluey_grey : p.theme.myColors.slate_blue)};
`;

const AmountToPayPrice = styled.Text`
  color: ${(p) => (p.theme?.isDark ? p.theme.myColors.bluey_grey : p.theme.myColors.slate_blue)};
`;
