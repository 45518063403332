import country from 'country-list-js';
import i18n from 'i18n-js';
import { Spinner, useToast } from 'native-base';
import React, { useState, useEffect, useRef } from 'react';
import { Text, TouchableOpacity, ScrollView, Alert, Animated, Platform } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/native';

import subscriptionApi from '../../apis/subscription';
import HeaderWithIcons from '../../components/common/HeaderWithIcons';
import IconDiscount_ from '../../components/svg/IconDiscount';
import Tick1_ from '../../components/svg/Tick1';
import { useTheme } from '../../components/template/ThemeProvider';
import MYButton from '../../components/ui/MYButton';
import useAsync from '../../hooks/useAsync';
import { fetchUserProfile } from '../../redux/slices/UserSlice';
import handleErrorResponse from '../../services/handleErrorResponse';

export default function SubscriptionHomeScreen({ navigation }) {
  const { colors, isDark, setScheme } = useTheme();
  const toast = useToast();
  const dispatch = useDispatch();
  const isRtl = userReduxState?.userSettings?.language?.toLowerCase() === 'fa';
  const userReduxState = useSelector((state) => state.user);
  const fadeAnim = useRef(new Animated.Value(1)).current; // Initial value for opacity: 0
  const { execute, status, response, error } = useAsync(() => {
    return subscriptionApi.getPlansList();
  }, true);

  const [banner, setbanner] = useState(0);
  const [loadingApi, setloadingApi] = useState(false);
  const [selected, setselected] = useState(null);

  function alert(title = 'Error', msg) {
    if (Platform.OS === 'web') {
      toast.show({ title: title, status: 'info', description: msg });
    } else {
      Alert.alert(title, msg, [{ text: 'ok', onPress: () => null, style: 'cancel' }]);
    }
  }

  function goToSuccessPage(result, message = '') {
    navigation.navigate('PaymentCallbackScreen', {
      result,
      message,
    });
  }

  function checkoutRequest() {
    if (!selected) {
      toast.show({
        description: 'please select a plan',
      });
      return;
    }
    // return navigation.navigate('PaymentScreen', { selectedPlan: response?.data?.find((a) => a._id === selected) });

    setloadingApi(true);

    return subscriptionApi
      .checkout(selected)
      .then((res) => {
        if (res?.data?.message === 'plan_is_free') {
          goToSuccessPage('success', ' Your payment is confirmed! ENJOY!');
          return;
        }

        if (res?.data?.paymentIntent && res?.data?.customer) {
          return navigation.navigate('PaymentScreen', {
            paymentIntent: res?.data?.paymentIntent,
            customer: res?.data?.customer,
            selectedPlan: response?.data?.find((a) => a._id === selected),
          });
        } else {
          throw new Error(res.data?.message || 'error');
        }
      })
      .catch((error) => {
        alert('Oops!', error.response ? handleErrorResponse(error.response.data?.message) : error?.message || 'problem in requesting data', [
          { text: 'ok', onPress: () => null, style: 'cancel' },
        ]);
      })
      .finally(() => {
        setloadingApi(false);
      });
  }

  useEffect(() => {
    if (!selected && response?.data?.length) {
      setselected(response?.data?.[response?.data?.length - 1]?._id);
      dispatch(fetchUserProfile());
    }
  }, [response]);
  const selectedPlan = response?.data?.find((a) => a._id === selected);
  const RenderEmpty = <Text style={{ textAlign: 'center', marginTop: 30 }}>No active plans found at the moment! come back later</Text>;

  const fadeIn = () => {
    return Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 500,
    }).start();
  };

  const fadeOut = () => {
    return Animated.timing(fadeAnim, {
      toValue: 0,
      duration: 500,
    }).start();
  };

  function showNextBanner() {
    fadeOut();
    setTimeout(() => {
      setbanner((prev) => (prev + 1 <= 2 ? prev + 1 : 0));

      fadeIn();
    }, 500);
  }

  useEffect(() => {
    const interval = setInterval(() => {
      showNextBanner();
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const renderDots = (activeIndex) => (
    <DotsContainer>
      <Dot active={activeIndex === 0} />
      <Dot active={activeIndex === 1} />
      <Dot active={activeIndex === 2} />
    </DotsContainer>
  );

  const subScribeBanner = (
    // <TouchableOpacity onPress={() => navigation.navigate('SubscriptionHomeScreen')}>
    <SubscribeNowWraper>
      <SubscribeNowContainer>
        <SubNowImage source={require('../../assets/images/unlimited_messages.png')} />
        <SubNowTxt>{i18n.t('subscription.send_unlimited_messages')}</SubNowTxt>
        {renderDots(0)}
      </SubscribeNowContainer>
    </SubscribeNowWraper>
    // </TouchableOpacity>
  );
  const subScribeBanner2 = (
    // <TouchableOpacity onPress={() => navigation.navigate('SubscriptionHomeScreen')}>
    <SubscribeNowWraper>
      <SubscribeNowContainer background={colors.lipstick}>
        <SubNowImage source={require('../../assets/images/who_liked_you.png')} />
        <SubNowTxt>{i18n.t('subscription.see_who_liked_you')}</SubNowTxt>
        {renderDots(1)}
      </SubscribeNowContainer>
    </SubscribeNowWraper>
    // </TouchableOpacity>
  );
  const subScribeBanner3 = (
    // <TouchableOpacity onPress={() => navigation.navigate('SubscriptionHomeScreen')}>
    <SubscribeNowWraper>
      <SubscribeNowContainer background={colors.bright_lavender}>
        <SubNowImage source={require('../../assets/images/matches.png')} />
        <SubNowTxt>{i18n.t('subscription.see_your_matches')}</SubNowTxt>
        {renderDots(2)}
      </SubscribeNowContainer>
    </SubscribeNowWraper>
    // </TouchableOpacity>
  );

  const bannersComponent = [subScribeBanner, subScribeBanner2, subScribeBanner3];

  const SelectedBanner = () => bannersComponent[banner];

  const userCurrency = country.findByIso2(userReduxState?.userInfo?.countryCode?.toUpperCase())?.currency?.code || 'USD';

  const subTitle =
    selectedPlan?.subTitle?.toLowerCase() === 'months' || !selectedPlan?.subTitle
      ? selectedPlan?.periodInDays === 31 || selectedPlan?.periodInDays === 30 || selectedPlan?.periodInDays === 29
        ? i18n.t('subscription.month')
        : i18n.t('subscription.months')
      : selectedPlan?.subTitle;

  const SubTitle2 = ({ plan }) => {
    return plan?.subTitle?.toLowerCase() === 'months' || !plan?.subTitle
      ? plan?.periodInDays === 31 || plan?.periodInDays === 30 || plan?.periodInDays === 29
        ? i18n.t('subscription.month')
        : i18n.t('subscription.months')
      : plan;
  };

  return (
    <Container>
      <ScrollView>
        <HeaderWithIcons title={i18n.t('subscription.header_title')} onPress={() => navigation.goBack()} />
        <Animated.View // Special animatable View
          style={{
            opacity: fadeAnim, // Bind opacity to animated value
          }}>
          <SelectedBanner />
        </Animated.View>
        <SelectedPlanContainer>
          <SelectedPlanTitle>{i18n.t('subscription.select_a_plan')}</SelectedPlanTitle>
          <SelectedPlanValue>{selectedPlan ? selectedPlan?.title + ' ' + subTitle : ''}</SelectedPlanValue>
        </SelectedPlanContainer>
        <PlansContainer>
          {status === 'pending' ? (
            <Spinner accessibilityLabel="Loading" />
          ) : response?.data?.length > 0 ? (
            response?.data?.map((item, index) => {
              const findedCurrency = item?.prices?.find((a) => a.currency === userCurrency);
              const price = findedCurrency ? findedCurrency?.price : item?.prices?.length && item?.prices?.find((a) => a.currency === 'USD')?.price;
              const oldprice = findedCurrency
                ? findedCurrency?.oldprice
                : item?.prices?.length && item?.prices?.find((a) => a.currency === 'USD')?.oldprice;
              const priceSign = findedCurrency ? country.findByIso2(userReduxState?.userInfo?.countryCode?.toUpperCase())?.currency?.symbol : '$';

              const discountPercent = oldprice ? Math.round(100 - (price * 100) / oldprice) : 0;

              return (
                <TouchableOpacity key={index} onPress={() => setselected(item?._id)} style={{ width: '30%', zIndex: 999 }}>
                  <PlanBox selected={selected === item?._id} index={index}>
                    {discountPercent ? (
                      <DiscountBox>
                        <IconDiscount_ />
                        <DiscountTextContainer>
                          <DiscountTxt>{discountPercent || 1}%</DiscountTxt>
                          <DiscountSubtitle>Discount</DiscountSubtitle>
                        </DiscountTextContainer>
                      </DiscountBox>
                    ) : null}
                    <PlanBoxTitle selected={selected === item?._id} index={index}>
                      {item?.title}
                    </PlanBoxTitle>
                    <PlanBoxSubTitle>
                      <SubTitle2 plan={item} />
                    </PlanBoxSubTitle>
                    {oldprice ? (
                      <PlanBoxOldPrice>
                        {oldprice} {priceSign}
                      </PlanBoxOldPrice>
                    ) : null}
                    <PlanBoxPrice hasOldprice={oldprice}>
                      {price} {priceSign}
                    </PlanBoxPrice>
                    {!discountPercent && selected === item?._id && <CheckedIcon width={20} height={20} />}
                  </PlanBox>
                </TouchableOpacity>
              );
            })
          ) : status !== 'pending' ? (
            RenderEmpty
          ) : null}
        </PlansContainer>
      </ScrollView>

      <TrustsIconsContainer>
        <TrustsIcons source={require('../../assets/images/secure_payment.png')} style={{ width: null, resizeMode: 'contain' }} />
      </TrustsIconsContainer>
      <Description isRtl={isRtl}>{i18n.t('subscription.description')}</Description>

      {status !== 'pending' && (
        <ButtonsContainer>
          <MYButton color="red" style={{ width: '100%' }} onPress={checkoutRequest} loading={loadingApi || status === 'pending'}>
            {i18n.t('auth.continue')}
          </MYButton>
        </ButtonsContainer>
      )}
    </Container>
  );
}

const Container = styled.View`
  display: flex;
  background-color: ${(p) => (p.theme?.isDark ? p.theme.myColors.dark2 : p.theme.myColors.white)};
  flex: 1;
`;

const PlansContainer = styled.View`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-top: 10px;
  margin-bottom: 0px;
  justify-content: center;
  padding: 10px 34px;
  width: 100%;
  /* background-color: red; */
  /* flex: 1; */
`;

const PlanBox = styled.View`
  border: 1px solid;
  border-radius: 8px;
  padding: 17px 20px 7px 20px;
  border-color: ${(p) => (p.selected ? p.theme.myColors.bright_sky_blue : p.theme?.isDark ? p.theme.myColors.dusk2 : p.theme.myColors.pale_grey)};
  height: ${(p) => `${110 + 24 * p.index}px`};
  width: ${Platform.OS === 'web' ? '90px' : 'auto'};
  position: relative;
  align-items: center;
  justify-content: center;
  margin: auto 2px 0;
  flex-shrink: 0;
  /* z-index: ${(p) => (p.selected ? 10 : 9)}; */

  /* background-color: blue; */
`;
const PlanBoxTitle = styled.Text`
  font-family: Gilroy-ExtraBold;
  font-size: ${(p) => `${28 + 8 * p.index}px`};
  color: ${(p) =>
    p.selected ? p.theme?.myColors.bright_sky_blue : p.theme?.isDark ? p.theme.myColors.light_blue_grey : p.theme.myColors.slate_blue};
`;
const PlanBoxSubTitle = styled.Text`
  font-family: Gilroy-Light;
  font-size: 10px;
  margin-bottom: 5px;
  color: ${(p) =>
    p.selected
      ? p.theme?.isDark
        ? p.theme.myColors.white
        : p.theme.myColors.dark
      : p.theme?.isDark
      ? p.theme.myColors.light_blue_grey
      : p.theme.myColors.slate_blue};
`;

const PlanBoxOldPrice = styled.Text`
  font-family: Gilroy-Medium;
  font-size: 10px;
  margin-top: auto;
  color: ${(p) => (p.theme?.isDark ? p.theme.myColors.white : p.theme.myColors.bright_sky_blue)};
  text-decoration: line-through;
`;

const PlanBoxPrice = styled.Text`
  font-family: Gilroy-Bold;
  font-size: 10px;
  margin-top: auto;
  color: ${(p) => (p?.hasOldprice ? p.theme.myColors.watermelon : p.theme?.isDark ? p.theme.myColors.white : p.theme.myColors.bright_sky_blue)};
`;

const CheckedIcon = styled(Tick1_)`
  position: absolute;
  top: 10px;
  right: -10px;
  z-index: 2;
`;

const ButtonsContainer = styled.View`
  padding: 0 34px;
  width: 100%;
  margin-bottom: 18px;
  flex-shrink: 0;
  margin-top: 34px;
`;

const SubscribeNowWraper = styled.View`
  padding: 0 39px 0;
`;
const SubscribeNowContainer = styled.View`
  margin-top: 21px;
  margin-bottom: 21px;
  background-color: ${(p) => (p?.background ? p?.background : p.theme.myColors.cornflower)};
  border-radius: 18px;
  padding: 25px 31px 25px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 120px;
`;
const SubNowImage = styled.Image`
  width: 50px;
  height: 50px;
  aspect-ratio: 1;
`;
const SubNowTxt = styled.Text`
  color: ${(p) => p.theme.myColors.white};
  font-family: Gilroy-SemiBold;
  font-size: 16px;
  flex: 1;
  margin-left: 15px;
  margin-right: 15px;
  text-align: center;
`;

const SelectedPlanContainer = styled.View`
  display: flex;
  flex-direction: row;
  padding: 0 39px 0;
`;

const SelectedPlanTitle = styled.Text`
  color: ${(p) => (p.theme?.isDark ? p.theme.myColors.white : p.theme.myColors.dusk)};
  font-family: Gilroy-Bold;
  font-size: 14px;
  flex: 1;
  /* margin-left: 13px; */
  text-align: left;
`;
const SelectedPlanValue = styled.Text`
  color: ${(p) => p.theme.myColors.bright_sky_blue};
  font-family: Gilroy-Bold;
  font-size: 14px;
  /* flex: 1; */
  /* margin-left: 13px; */
`;
const DiscountBox = styled.View`
  position: absolute;
  z-index: 99;
  right: -6px;
  top: -15px;
  /* flex: 1; */
  /* margin-left: 13px; */
`;
const DiscountTextContainer = styled.View`
  position: absolute;
  z-index: 99;
  right: 0;
  left: 0;
  top: 12;
  /* flex: 1; */
  /* margin-left: 13px; */
`;
const DiscountTxt = styled.Text`
  color: ${(p) => p.theme.myColors.white};
  font-size: 12px;
  text-align: center;
  font-family: Gilroy-Bold;
`;
const DiscountSubtitle = styled.Text`
  margin-top: -3;
  color: ${(p) => p.theme.myColors.white};
  font-size: 6px;
  text-align: center;
  font-family: Gilroy-Medium;
`;
const DotsContainer = styled.View`
  flex-direction: column;
`;
const Dot = styled.View`
  width: 10px;
  height: 10px;
  border-radius: 9999px;
  background: #ffffff47;
  background: ${(p) => (p.active ? `#ffffff` : '#ffffff47')};
  margin-top: 6px;
  /* border: ${(p) => (p.active ? `1px solid #ddd` : 'none')}; */
`;

const TrustsIconsContainer = styled.View`
  /* aspect-ratio: 1; */
  /* margin-top: -20px; */
  /* margin-bottom:-30px; */
  padding: 0 34px 0;
`;
const TrustsIcons = styled.Image`
  height: 50px;
`;
const Description = styled.Text`
  font-family: Gilroy-Medium;
  margin-top: 10px;
  font-size: 12px;
  color: ${(p) => (p.theme?.isDark ? p.theme.myColors.bluey_grey : p.theme.myColors.slate_blue)};
  padding: 10px 34px;
  /* text-align: ${(p) => (p.isRtl ? 'right' : 'left')}; */
  text-align: center;
`;
