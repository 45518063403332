// import country from 'country-list-js';
import i18n from 'i18n-js';
import { Spinner, useToast } from 'native-base';
import React, { useEffect, useState } from 'react';
import { Alert, RefreshControl, Text, TouchableOpacity, Platform, FlatList } from 'react-native';
import { useSelector } from 'react-redux';
import styled from 'styled-components/native';

import evaluationApi from '../../apis/evaluation';
import HeaderWithIcons from '../../components/common/HeaderWithIcons';
import ProfileCardWithTitle from '../../components/common/ProfileCardWithTitle';
import handleErrorResponse from '../../services/handleErrorResponse';
import calculateAgeFromDate from '../../utils/calculateAgeFromDate';

const limit = 20;

export default function YourLikesScreen({ navigation }) {
  const [paginationPage, setPaginationPage] = useState(1);
  const [isHaveMoreData, setisHaveMoreData] = useState(true);
  const [Data, setData] = useState([]);
  const [loading, setloading] = useState(false);

  const userReduxState = useSelector((state) => state.user);
  const isRtl = userReduxState?.userSettings?.language?.toLowerCase() === 'fa';
  const toast = useToast();

  function alert(title = undefined, msg, footer = [{ text: 'ok', onPress: () => null, style: 'cancel' }]) {
    if (Platform.OS === 'web') {
      toast.show({ title: title, status: 'info', description: msg });
    } else {
      Alert.alert(title, msg, footer);
    }
  }

  function getUsers(page = 1) {
    setloading(true);

    return evaluationApi
      .getWhoILiked(`page=${page}&limit=${limit}`)
      .then((res) => {
        setloading(false);

        if (res?.data?.results?.length) {
          setPaginationPage((prev) => (page === 1 ? 1 : prev + 1));

          return page === 1 ? setData(res.data.results) : setData((prev) => [...(prev || []), ...res.data.results]);
        } else {
          setisHaveMoreData(false);
        }
      })
      .catch((error) => {
        setloading(false);

        // console.log('error send msg', error.response);
        alert('Error', error.response ? handleErrorResponse(error.response.data?.message) : 'Problem in getting data');
      });
  }

  useEffect(() => {
    getUsers(1);
  }, []);

  const RenderEmpty = <Text style={{ textAlign: 'center', marginTop: 30 }}>-</Text>;

  const renderItem = ({ item: _item }) => {
    const item = _item?.userDetails?.[0];

    return (
      <Item>
        <TouchableOpacity onPress={() => navigation.navigate('SingleUserProfileScreen', { userData: item })}>
          <ProfileCardWithTitle
            img={item?.avatar ? { uri: item?.avatar } : require('../../assets/images/defaultavatar1.png')}
            name={item?.name}
            age={item?.birthdate && calculateAgeFromDate(item?.birthdate) + ' ' + i18n.t('global.years_old')}
            location={item?.city}
          />
        </TouchableOpacity>
      </Item>
    );
  };

  return (
    <>
      <Container>
        <HeaderWithIcons
          title={
            isRtl
              ? i18n.t('discover.your_likes_likes') + ' ' + i18n.t('discover.your_likes_your')
              : i18n.t('discover.your_likes_your') + ' ' + i18n.t('discover.your_likes_likes')
          }
          onPress={() => navigation.goBack()}
        />

        {!Data?.length && loading && <LoadingSpinner size="lg" />}

        {Data?.length > 0 ? (
          <FlatList
            data={Data}
            renderItem={renderItem}
            keyExtractor={(item, index) => index}
            numColumns={2}
            refreshControl={<RefreshControl refreshing={Data?.length > 0 && loading} onRefresh={() => getUsers(1)} />}
            ListFooterComponent={isHaveMoreData && Data?.length && Data?.length >= limit ? <LoadingSpinner size="lg" /> : null}
            onEndReachedThreshold={2}
            onEndReached={() => {
              if (isHaveMoreData && Data?.length && Data?.length >= limit) {
                getUsers(paginationPage + 1);
              }
            }}
          />
        ) : (
          !loading && RenderEmpty
        )}
      </Container>
    </>
  );
}

const Container = styled.View`
  padding: 0 20px;
  display: flex;
  background-color: ${(p) => (p.theme?.isDark ? p.theme.myColors.dark2 : p.theme.myColors.white)};
  flex: 1;
`;

const Item = styled.View`
  /* width: 33%; */
  width: 50%;
`;
const LoadingSpinner = styled(Spinner)`
  width: 100%;
`;
