import country from 'country-list-js';
import { manipulateAsync } from 'expo-image-manipulator';
import * as ImagePicker from 'expo-image-picker';
import * as Linking from 'expo-linking';
import i18n from 'i18n-js';
import { Actionsheet, useDisclose, Spinner, useToast } from 'native-base';
import React, { useState, useEffect, useMemo } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { View, Image, Text, Alert, TouchableOpacity, Platform } from 'react-native';
import CountryPicker, { DEFAULT_THEME, DARK_THEME } from 'react-native-country-picker-modal';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/native';

import usersApi from '../../apis/users';
import HeaderWithIcons from '../../components/common/HeaderWithIcons';
import {
  _martialStatusOptions,
  _jobStatusEnumIdOptions,
  _educationEnumIdOptions,
  _ethnicityEnumIdOptions,
  _languageEnumIdOptions,
  _interestEnumIdOptions,
  _childrensEnumIdOptions,
  _smokeEnumIdOptions,
  _drinkEnumIdOptions,
  formRules,
} from '../../components/pages/myProfile/consts';
import IconDelete2 from '../../components/svg/IconDelete2';
import IconDrink from '../../components/svg/IconDrink';
import IconEducation from '../../components/svg/IconEducation';
import IconFavorite from '../../components/svg/IconFavorite';
import IconHeight from '../../components/svg/IconHeight';
import IconJob from '../../components/svg/IconJob';
import IconLanguage2 from '../../components/svg/IconLanguage2';
import IconLocation2 from '../../components/svg/IconLocation2';
import IconMartialStatus from '../../components/svg/IconMartialStatus';
import IconPlus from '../../components/svg/IconPlus';
import IconSmiles from '../../components/svg/IconSmiles';
import IconSmoke from '../../components/svg/IconSmoke';
import IconTarget from '../../components/svg/IconTarget';
import IconUser from '../../components/svg/IconUser';
import SelectImageDark from '../../components/svg/SelectImageDark';
import SelectImageLight from '../../components/svg/SelectImageLight';
import { useTheme } from '../../components/template/ThemeProvider';
import MYButton from '../../components/ui/MYButton';
import Myinput from '../../components/ui/MYInput';
import MySelect from '../../components/ui/MySelect';
import { close, open } from '../../redux/slices/ModalSlice';
import handleErrorResponse from '../../services/handleErrorResponse';
import { DataURIToBlob } from '../../utils/utils';

export default function EditMyProfileScreen({ navigation }) {
  const dispatch = useDispatch();
  const toast = useToast();
  const { colors, isDark, setScheme } = useTheme();
  const userReduxState = useSelector((state) => state.user)?.userInfo;
  const { isOpen, onOpen, onClose } = useDisclose();
  const [uploadedImageUri, setuploadedImageUri] = useState(null);
  const [imagesGallery, setImagesGallery] = useState(userReduxState?.imagesUrls);

  function alert(title = undefined, msg, footer = [{ text: 'ok', onPress: () => null, style: 'cancel' }]) {
    if (Platform.OS === 'web') {
      toast.show({ title, status: 'info', description: msg });
    } else {
      Alert.alert(title, msg, footer);
    }
  }

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    defaultValues: useMemo(() => {
      return {
        countryCode: userReduxState?.countryCode || '',
        state: userReduxState?.state,
        city: userReduxState?.city,
        name: userReduxState?.name || '',
        height: `${userReduxState?.profile?.height || ''}`,
        martialStatus: `${userReduxState?.profile?.martialStatus || ''}`,
        jobStatusEnumId: `${userReduxState?.profile?.jobStatusEnumId || ''}`,
        educationEnumId: `${userReduxState?.profile?.educationEnumId || ''}`,
        // ethnicityEnumId: `${userReduxState?.profile?.ethnicityEnumId || ''}`,
        // languageEnumId: `${userReduxState?.profile?.languageEnumId || ''}`,
        interestEnumId: `${userReduxState?.profile?.interestEnumId || ''}`,
        childrensEnumId: `${userReduxState?.profile?.childrensEnumId || ''}`,
        smokeEnumId: `${userReduxState?.profile?.smokeEnumId || ''}`,
        drinkEnumId: `${userReduxState?.profile?.drinkEnumId || ''}`,
      };
    }, [userReduxState]),
  });

  const selectedCountry = watch('countryCode');
  const selectedState = watch('state');
  const selectedCity = watch('city');
  const CountryFullName = country.findByIso2(selectedCountry?.toUpperCase())?.name;

  const martialStatusOptions = _martialStatusOptions(i18n);
  const jobStatusEnumIdOptions = _jobStatusEnumIdOptions(i18n);
  const educationEnumIdOptions = _educationEnumIdOptions(i18n);
  const ethnicityEnumIdOptions = _ethnicityEnumIdOptions(i18n);
  const languageEnumIdOptions = _languageEnumIdOptions(i18n);
  const interestEnumIdOptions = _interestEnumIdOptions(i18n);
  const childrensEnumIdOptions = _childrensEnumIdOptions(i18n);
  const smokeEnumIdOptions = _smokeEnumIdOptions(i18n);
  const drinkEnumIdOptions = _drinkEnumIdOptions(i18n);

  function onSubmit(formVals) {
    if (formVals?.height && (formVals?.height > 250 || formVals?.height < 70)) {
      toast.show({
        description: 'Height is invalid',
      });
      return;
    }
    if (!formVals?.countryCode) {
      toast.show({
        description: 'country is required',
      });
      return;
    }

    if (formVals?.height) {
      // convert persian numbers to english numbers
      formVals.height = formVals.height.replace(/[۰-۹]/g, function (w) {
        return w.charCodeAt(0) - 1776;
      });

      // convert arabic numbers to english numbers
      formVals.height = formVals.height.replace(/[٠-٩]/g, function (w) {
        return w.charCodeAt(0) - 1632;
      });
    }

    usersApi
      .updateProfile({
        name: formVals?.name,
        'profile.martialStatus': formVals?.martialStatus,
        'profile.jobStatusEnumId': formVals?.jobStatusEnumId,
        'profile.educationEnumId': formVals?.educationEnumId,
        // 'profile.ethnicityEnumId': formVals?.ethnicityEnumId,
        // 'profile.languageEnumId': formVals?.languageEnumId,
        'profile.interestEnumId': formVals?.interestEnumId,
        'profile.childrensEnumId': formVals?.childrensEnumId,
        'profile.smokeEnumId': formVals?.smokeEnumId,
        'profile.drinkEnumId': formVals?.drinkEnumId,
        'profile.height': formVals?.height,
        countryCode: formVals?.countryCode,
        state: `${formVals?.state}`,
        city: `${formVals?.city}`,
      })
      .then((res) => {
        // console.log(res);
        if (res.status === 201 || res.status === 200) {
          navigation.goBack();
        } else {
          alert('Error', res.data?.message ? handleErrorResponse(res.data?.message) : 'Error in saving data');
        }
      })
      .catch((error) => {
        alert('Error', error.response.data?.message ? handleErrorResponse(error.response.data?.message) : 'Error in saving data');
      });
  }

  useEffect(() => {
    (async () => {
      if (Platform.OS !== 'web') {
        const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
        if (status !== 'granted') {
          alert('Sorry, we need camera roll permissions to make this work!');
        }
      }
    })();
  }, []);

  function handleOpenModal() {
    dispatch(
      open({
        content: () => (
          <View style={{ paddingTop: 50, paddingBottom: 50 }}>
            <Spinner accessibilityLabel="Loading" />
          </View>
        ),
      })
    );
  }

  async function uploadAvatarImageToServer(imageUri) {
    if (!imageUri) return;

    const file = DataURIToBlob(imageUri);

    handleOpenModal();
    const formData = new FormData();

    if (Platform.OS === 'web') {
      formData.append('file', file);
    } else {
      formData.append('file', {
        uri: imageUri,
        name: `profile_photo.jpeg`,
        type: `image/jpeg`,
      });
    }
    usersApi
      .updateProfileAvatar(formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(() => {
        dispatch(close());
        setuploadedImageUri(imageUri);
      })
      .catch(() => {
        dispatch(close());
      });
  }

  async function uploadGalleryImageToServer(imageUri) {
    if (!imageUri) return;

    const file = DataURIToBlob(imageUri);

    handleOpenModal();
    const formData = new FormData();

    if (Platform.OS === 'web') {
      formData.append('file', file);
    } else {
      formData.append('file', {
        uri: imageUri,
        name: `profile_photo.jpeg`,
        type: `image/jpeg`,
      });
    }

    usersApi
      .updateProfileGallery(formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        dispatch(close());
        setImagesGallery(res?.data?.userInfo?.imagesUrls);
        // setuploadedImageUri(imageUri);
      })
      .catch((err) => {
        dispatch(close());
        // console.log(err.response);
        alert('Error', err?.response?.data?.message);
      });
  }

  async function deleteGalleryImageToServer(imagestr) {
    handleOpenModal();

    console.log(imagestr);
    usersApi
      .deleteProfileGallery(imagestr)
      .then((res) => {
        console.log(res.data);

        dispatch(close());
        setImagesGallery(res?.data?.userInfo?.imagesUrls);
        // setuploadedImageUri(imageUri);
      })
      .catch((err) => {
        console.log(err);
        dispatch(close());
        alert('Error', err?.response?.data?.message);
      });
  }

  const takeImage = async () => {
    onClose();

    const { status } = await ImagePicker.requestCameraPermissionsAsync();

    if (status !== 'granted') {
      if (Platform.OS === 'ios') {
        alert(
          'No Permission',
          'Allow access to photos within this device.',
          [
            {
              text: 'Go to setting',
              onPress: () => Linking.openURL('app-settings:'),
            },
            {
              text: 'Cancel',
              style: 'cancel',
            },
          ],
          {
            cancelable: true,
          }
        );
      } else {
        alert('Sorry, we need camera roll permissions to make this work!');
      }

      return;
    }

    try {
      const result = await ImagePicker.launchCameraAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.All,
        allowsEditing: true,
        aspect: [1, 1],
        quality: 1,
        base64: true,
      });

      if (!result.cancelled) {
        // setImage(result.uri);
      }
    } catch (error) {
      // console.log(error, error?.message, error?.response);
    }
  };

  const pickImage = async (isAvatar = true) => {
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [1, 1],
      quality: 1,
      base64: true,
      exif: false,
    });

    const manipResult = await manipulateAsync(result.uri, [{ resize: { height: 500, width: 500 } }], { format: 'jpeg' });

    if (!result.cancelled && manipResult.uri) {
      onClose();

      if (isAvatar) {
        uploadAvatarImageToServer(manipResult.uri);
      } else {
        uploadGalleryImageToServer(manipResult.uri);
      }
      // console.log(result);
      // setImage(result.uri);
    }
  };

  return (
    <Container>
      <HeaderWithIcons
        title={i18n.t('edit_my_profile.header_title')}
        onPress={() => navigation.goBack()}
        rightNode={
          <TouchableOpacity onPress={() => navigation.navigate('SingleUserProfileScreen', { userData: userReduxState, disableActions: true })}>
            <PreviewBtn>{i18n.t('edit_my_profile.preview')}</PreviewBtn>
          </TouchableOpacity>
        }
      />

      <ContainerScrillView>
        <AvatarBox>
          <Avatar
            source={
              uploadedImageUri || userReduxState?.avatar
                ? { uri: uploadedImageUri || userReduxState?.avatar }
                : require('../../assets/images/defaultavatar1.png')
            }
          />

          <ChangeAvatarIcon>
            <TouchableOpacity onPress={pickImage} style={{ marginRight: 22 }}>
              {isDark ? <SelectImageDark /> : <SelectImageLight />}
            </TouchableOpacity>
          </ChangeAvatarIcon>
        </AvatarBox>
        <GalleryContainer>
          {imagesGallery?.map((item) => (
            <GalleryItemBox>
              <Image source={{ uri: item }} style={{ width: '100%', height: 'auto', aspectRatio: 1, borderRadius: 8, backgroundColor: '#ddd' }} />
              <IconDeleteContainer onPress={() => deleteGalleryImageToServer(item)}>
                <IconDelete2 color="#fff" />
              </IconDeleteContainer>
            </GalleryItemBox>
          ))}
          {(!imagesGallery || imagesGallery?.length < 3) && (
            <GalleryItemBox>
              <AddNewImageBox onPress={() => pickImage(false)} style={{ marginRight: 22 }}>
                <IconPlus />
                <AddNewImageBoxText>{i18n.t('edit_my_profile.btn_add_photo')}</AddNewImageBoxText>
              </AddNewImageBox>
            </GalleryItemBox>
          )}
        </GalleryContainer>

        <InputsBox>
          {CountryFullName ? <InputTitle>{i18n.t('auth.your_country')}</InputTitle> : null}
          <TouchableOpacity
            onPress={() =>
              navigation.navigate('ModalSelectCity', {
                onGoBack: (v) => {
                  if (v?.name) {
                    setValue('countryCode', v?.countryCode);
                    setValue('state', v?.stateCode);
                    setValue('city', v?.name);
                  }
                },
                initialCountry: selectedCountry,
              })
            }>
            <CountryFieldBox>
              <CountryFieldIcon>
                <IconLocation2 color={isDark ? colors.bluey_grey : colors.dark} />
              </CountryFieldIcon>
              <CountryFieldTxt>{selectedCity && `${selectedCity}, ${selectedState}, ${CountryFullName}`}</CountryFieldTxt>
            </CountryFieldBox>
          </TouchableOpacity>
        </InputsBox>
        {/* <Controller
        control={control}
        rules={formRules.countryCode}
        render={({ field: { onChange, onBlur, value } }) => (
          <InputsBox>
            {value ? <InputTitle>{i18n.t('edit_my_profile.country')}</InputTitle> : null}
            <CountryPicker
              theme={isDark ? DARK_THEME : DEFAULT_THEME}
              countryCode={value || null}
              withFilter
              withFlag
              withCountryNameButton
              withAlphaFilter
              // withCallingCode
              withEmoji
              onSelect={(country) => {
                onChange(country.cca2);
                setValue('city', '');
              }}
              // visible
            />
          </InputsBox>
        )}
        name="countryCode"
        defaultValue=""
      /> */}
        {/* <Controller
        control={control}
        rules={formRules.city}
        render={({ field: { onChange, onBlur, value } }) => (
          <InputsBox>
            {value ? <InputTitle>City</InputTitle> : null}
            <MySelect
              variant="unstyled"
              minWidth="100%"
              InputLeftElement={<IconLocation2 color={isDark ? colors.bluey_grey : colors.dark} />}
              placeholder="City" // mx={4}
              onValueChange={(itemValue) => onChange(itemValue)}
              selectedValue={value || null}
              options={citiesOptions}
            />
          </InputsBox>
        )}
        name="city"
        defaultValue=""
      /> */}
        <Controller
          control={control}
          rules={formRules.name}
          render={({ field: { onChange, onBlur, value } }) => (
            <InputsBox>
              {value ? <InputTitle>{i18n.t('auth.placeholder_name')}</InputTitle> : null}
              <Myinput
                variant="unstyled"
                InputLeftElement={<IconUser color={isDark ? colors.bluey_grey : colors.dark} />}
                placeholder={i18n.t('auth.placeholder_name')} // mx={4}
                onChangeText={(txt) => onChange(txt)}
                value={value || ''}
              />
            </InputsBox>
          )}
          name="name"
          defaultValue=""
        />
        <Controller
          control={control}
          rules={formRules.martialStatus}
          render={({ field: { onChange, onBlur, value } }) => (
            <InputsBox>
              {value ? <InputTitle>{i18n.t('userprofile.marital_status')}</InputTitle> : null}
              <MySelect
                variant="unstyled"
                minWidth="100%"
                InputLeftElement={<IconMartialStatus color={isDark ? colors.bluey_grey : colors.dark} />}
                placeholder={i18n.t('userprofile.marital_status')} // mx={4}
                onValueChange={(itemValue) => onChange(itemValue)}
                selectedValue={value}
                options={martialStatusOptions}
              />
            </InputsBox>
          )}
          name="martialStatus"
          defaultValue=""
        />
        <Controller
          control={control}
          rules={formRules.jobStatusEnumId}
          render={({ field: { onChange, onBlur, value } }) => (
            <InputsBox>
              {value ? <InputTitle>{i18n.t('userprofile.job_status')}</InputTitle> : null}
              <MySelect
                variant="unstyled"
                minWidth="100%"
                InputLeftElement={<IconJob color={isDark ? colors.bluey_grey : colors.dark} />}
                placeholder={i18n.t('userprofile.job_status')} // mx={4}
                onValueChange={(itemValue) => onChange(itemValue)}
                selectedValue={value}
                options={jobStatusEnumIdOptions}
              />
            </InputsBox>
          )}
          name="jobStatusEnumId"
          defaultValue=""
        />
        <Controller
          control={control}
          rules={formRules.educationEnumId}
          render={({ field: { onChange, onBlur, value } }) => (
            <InputsBox>
              {value ? <InputTitle>{i18n.t('userprofile.education')}</InputTitle> : null}
              <MySelect
                variant="unstyled"
                minWidth="100%"
                InputLeftElement={<IconEducation color={isDark ? colors.bluey_grey : colors.dark} />}
                placeholder={i18n.t('userprofile.education')} // mx={4}
                onValueChange={(itemValue) => onChange(itemValue)}
                selectedValue={value}
                options={educationEnumIdOptions}
              />
            </InputsBox>
          )}
          name="educationEnumId"
          defaultValue=""
        />
        {/* <Controller
          control={control}
          rules={formRules.ethnicityEnumId}
          render={({ field: { onChange, onBlur, value } }) => (
            <InputsBox>
              {value ? <InputTitle>{i18n.t('userprofile.ethnicity')}</InputTitle> : null}
              <MySelect
                variant="unstyled"
                minWidth="100%"
                InputLeftElement={<IconTarget color={isDark ? colors.bluey_grey : colors.dark} />}
                placeholder={i18n.t('userprofile.ethnicity')} // mx={4}
                onValueChange={(itemValue) => onChange(itemValue)}
                selectedValue={value}
                options={ethnicityEnumIdOptions}
              />
            </InputsBox>
          )}
          name="ethnicityEnumId"
          defaultValue=""
        /> */}
        {/* <Controller
          control={control}
          rules={formRules.languageEnumId}
          render={({ field: { onChange, onBlur, value } }) => (
            <InputsBox>
              {value ? <InputTitle>{i18n.t('userprofile.language')}</InputTitle> : null}

              <MySelect
                variant="unstyled"
                minWidth="100%"
                InputLeftElement={<IconLanguage2 color={isDark ? colors.bluey_grey : colors.dark} />}
                placeholder={i18n.t('userprofile.language')} // mx={4}
                onValueChange={(itemValue) => onChange(itemValue)}
                selectedValue={value}
                options={languageEnumIdOptions}
              />
            </InputsBox>
          )}
          name="languageEnumId"
          defaultValue=""
        /> */}
        <Controller
          control={control}
          rules={formRules.interestEnumId}
          render={({ field: { onChange, onBlur, value } }) => (
            <InputsBox>
              {value ? <InputTitle>{i18n.t('userprofile.interest')}</InputTitle> : null}

              <MySelect
                variant="unstyled"
                minWidth="100%"
                InputLeftElement={<IconFavorite color={isDark ? colors.bluey_grey : colors.dark} />}
                placeholder={i18n.t('userprofile.interest')} // mx={4}
                onValueChange={(itemValue) => onChange(itemValue)}
                selectedValue={value}
                options={interestEnumIdOptions}
              />
            </InputsBox>
          )}
          name="interestEnumId"
          defaultValue=""
        />
        <Controller
          control={control}
          rules={formRules.childrensEnumId}
          render={({ field: { onChange, onBlur, value } }) => (
            <InputsBox>
              {value ? <InputTitle>{i18n.t('userprofile.children')}</InputTitle> : null}

              <MySelect
                variant="unstyled"
                minWidth="100%"
                InputLeftElement={<IconSmiles color={isDark ? colors.bluey_grey : colors.dark} />}
                placeholder={i18n.t('userprofile.children')} // mx={4}
                onValueChange={(itemValue) => onChange(itemValue)}
                selectedValue={value}
                options={childrensEnumIdOptions}
              />
            </InputsBox>
          )}
          name="childrensEnumId"
          defaultValue=""
        />
        <Controller
          control={control}
          rules={formRules.smokeEnumId}
          render={({ field: { onChange, onBlur, value } }) => (
            <InputsBox>
              {value ? <InputTitle>{i18n.t('userprofile.smoke')}</InputTitle> : null}

              <MySelect
                variant="unstyled"
                minWidth="100%"
                InputLeftElement={<IconSmoke color={isDark ? colors.bluey_grey : colors.dark} />}
                placeholder={i18n.t('userprofile.smoke')} // mx={4}
                onValueChange={(itemValue) => onChange(itemValue)}
                selectedValue={value}
                options={smokeEnumIdOptions}
              />
            </InputsBox>
          )}
          name="smokeEnumId"
          defaultValue=""
        />
        <Controller
          control={control}
          rules={formRules.drinkEnumId}
          render={({ field: { onChange, onBlur, value } }) => (
            <InputsBox>
              {value ? <InputTitle>{i18n.t('userprofile.drink')}</InputTitle> : null}

              <MySelect
                variant="unstyled"
                minWidth="100%"
                InputLeftElement={<IconDrink color={isDark ? colors.bluey_grey : colors.dark} />}
                placeholder={i18n.t('userprofile.drink')} // mx={4}
                onValueChange={(itemValue) => onChange(itemValue)}
                selectedValue={value}
                options={drinkEnumIdOptions}
              />
            </InputsBox>
          )}
          name="drinkEnumId"
          defaultValue=""
        />
        <Controller
          control={control}
          rules={formRules.height}
          render={({ field: { onChange, onBlur, value } }) => (
            <InputsBox>
              {value ? <InputTitle>{i18n.t('userprofile.height')} (cm)</InputTitle> : null}

              <Myinput
                variant="unstyled"
                InputLeftElement={<IconHeight color={isDark ? colors.bluey_grey : colors.dark} />}
                placeholder={i18n.t('userprofile.height') + ' (cm)'} // mx={4}
                onChangeText={(txt) => onChange(txt)}
                value={value || ''}
              />
            </InputsBox>
          )}
          name="height"
          defaultValue=""
        />
        {/* <Controller
        control={control}
        rules={formRules.children}
        render={({ field: { onChange, onBlur, value } }) => (
          <InputsBox>
            <Myinput
              variant="unstyled"
              InputLeftElement={<IconUser color={isDark ? colors.bluey_grey : colors.dark} />}
              placeholder={i18n.t('userprofile.children')} // mx={4}
              onChangeText={(txt) => onChange(txt)}
              value={value || ''}
            />
          </InputsBox>
        )}
        name="children"
        defaultValue=""
      /> */}
        {/* {errors.firstName && <Text>This is required.</Text>} */}
      </ContainerScrillView>

      <BtnContainer>
        <MYButton color="blue" style={{ width: '100%' }} onPress={handleSubmit(onSubmit)}>
          {i18n.t('global.save')}
        </MYButton>
      </BtnContainer>

      <Actionsheet isOpen={isOpen} onClose={onClose}>
        <Actionsheet.Content>
          <Actionsheet.Item onPress={takeImage}>{i18n.t('edit_my_profile.take_a_picture')}</Actionsheet.Item>
          <Actionsheet.Item onPress={pickImage}>{i18n.t('edit_my_profile.upload_form_gallrey')}</Actionsheet.Item>
        </Actionsheet.Content>
      </Actionsheet>
    </Container>
  );
}

const Container = styled.View`
  display: flex;
  background-color: ${(p) => (p.theme?.isDark ? p.theme.myColors.dark2 : p.theme.myColors.white)};
  flex: 1;
  /* align-items: center; */
  /* justify-content: space-between; */
  /* overflow: hidden; */
  width: 100%;
`;
const ContainerScrillView = styled.ScrollView`
  display: flex;
  flex: 1;
  /* align-items: center; */
  /* justify-content: space-between; */
  /* overflow: hidden; */
  width: 100%;
`;

const InputsBox = styled.View`
  border-radius: 28px;
  border: solid 1px;
  border-color: ${(p) => (p.theme.isDark ? p.theme.myColors.dusk2 : p.theme.myColors.pale_grey)};
  padding: 15px 20px;
  margin: 10px 35px;
  position: relative;
`;

const PreviewBtn = styled.Text`
  color: ${(p) => p.theme.myColors.cornflower};
  font-family: Gilroy-SemiBold;
  font-size: 12px;
  margin-top: 13px;
`;

const InputTitle = styled.Text`
  color: ${(p) => (p.theme.isDark ? p.theme.myColors.light_blue_grey : p.theme.myColors.bluey_grey)};
  position: absolute;
  left: 20px;
  top: -10px;
  font-family: Gilroy-Light;
  font-size: 12px;
  padding: 0 6px;
  background-color: ${(p) => (p.theme.isDark ? p.theme.myColors.dark2 : p.theme.myColors.white)};
`;

const CountryFieldBox = styled.View`
  flex-direction: row;
  align-items: center;
  padding: 8px 0;
`;

const CountryFieldIcon = styled.View`
  margin-right: 6px;
`;
const CountryFieldTxt = styled.Text`
  color: ${(p) => (p.theme.isDark ? p.theme.myColors.pale_grey : p.theme.myColors.dark2)};
  font-family: Gilroy-Medium;
  font-size: 12px;
`;

const GalleryContainer = styled.View`
  padding: 15px 20px;
  margin: 10px 35px 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
`;

const GalleryItemBox = styled.View`
  width: 30%;
  margin: 4px;
`;

const AddNewImageBox = styled.TouchableOpacity`
  background-color: ${(p) => (p.theme?.isDark ? p.theme.myColors.dark3 : p.theme.myColors.pale_grey2)};
  width: 100%;
  height: ${Platform.OS === 'web' ? '52px' : 'auto'};
  aspect-ratio: 1;
  border-radius: 8px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: dashed 1px;
  border-color: ${(p) => (p.theme?.isDark ? p.theme.myColors.light_blue_grey : p.theme.myColors.slate_blue)};
`;
const AddNewImageBoxText = styled.Text`
  color: ${(p) => (p.theme?.isDark ? p.theme.myColors.light_blue_grey : p.theme.myColors.slate_blue)};
  font-size: 12px;
  margin-top: 6px;
`;

const BtnContainer = styled.View`
  margin: 10px 35px;
`;

const AvatarBox = styled.View`
  border-radius: 1000px;
  padding: 4px;
  border: 1.5px solid;
  border-color: ${(p) => (p.theme?.isDark ? p.theme.myColors.dusk2 : p.theme.myColors.pale_grey)};
  margin: 40px auto 35px;
  position: relative;
`;
const Avatar = styled.Image`
  border-radius: 1000px;
  width: 93px;
  height: ${Platform.OS === 'web' ? '93px' : 'auto'};
  aspect-ratio: 1;
`;
const ChangeAvatarIcon = styled.View`
  position: absolute;
  bottom: -17px;
  margin: 0 auto;
  left: 31px;
`;
const IconDeleteContainer = styled.TouchableOpacity`
  position: absolute;
  top: 30%;
  left: 30%;
  margin: 0 auto;
  padding: 12px;
  background-color: #23282d;
  opacity: 0.8;
  border-radius: 1000px;
`;
